import slipcomp from "../utils/slipcomp";

export default class Constants {
    static DEFAULTSLIPSIZE = 20;
    static DATA_TYPE_SPORTDATA = "SPORTDATA"
    static DATA_TYPE_LEGACY = "LEGACY"


    static MEMBER_TYPE_REGULAR = 1
    static MEMBER_TYPE_AGENT = 2

    static JACKPOT_HOME = 1;
    static JACKPOT_AWAY = 2;
    static JACKPOT_DRAW = 3;
    static MAX_WIN = 5000.00;
    static TAX_TYPE_TOT = 'tot';
    static TAX_TYPE_VAT = 'vat';
    static TAX_TYPE_NONE = 'none';
    static TAX_TYPE_WITHHOLD = 'withhold';
    static TAX_TYPE_WINNING = 'winning';

    static FNB_WALLET = 1;
    static FNB_EFT = 2;
    static EMALI = 3;
    static ECOCASH = 4;
    static MPESA = 5;
    static MOMO = 6;

    static IMAGE_LOCATION_MAIN_BANNER = 1;
    static IMAGE_LOCATION_SIDE_BANNER = 2;

    static DEFAULT_TEMPLATE = 'default'
    static WIDE_SLIDE_TEMPLATE = 'wideslide'
    static DEFAULTSLIPSIZE = 20

    static NARROW_TEMPLATE = 1
    static WIDE_TEMPLATE = 2

    static LAST_MINUTE_MATCHS = 1
    static TODAY_MATCHS = 2

    static WALLET_STRUCTURE_PAYABLE = 'with_payable'
    static WALLET_STRUCTURE_BONUS = 'with_bonus'
    static WALLET_STRUCTURE_BONUS_PAYABLE = 'with_bonus_payable'
    static WALLET_STRUCTURE_SINGLE = 'single'

    static SUBMENU_GRID = 1
    static SUBMENU_SCROLLABLE = 2
    static MOBILE_MATCHLIST_VERTICAL6 = 1
    static MOBILE_MATCHLIST_HORIZONTAL3 = 2
    static MOBILE_FIXEDFOOTER_SLIP = 1
    static MOBILE_FIXEDFOOTER_MENU4 = 2
    static MOBILE_MENUBAR_DEFAULT = 1
    static MOBILE_MENUBAR_LOGO_TOP = 2
    static DESKTOP_MENUBAR_LOGIN_DISPLAYED = 1
    static DESKTOP_MENUBAR_LOG_REG_LNK = 2

    static PAYMENT_OTP_NONE = 1
    static PAYMENT_OTP_ALL = 2
    static PAYMENT_OTP_DIFFERNT_USERIDENTIFIER = 3

    static OTHER
    static JACKPOT_WINCHOICES = {
        [Constants.JACKPOT_HOME]: 'Home',
        [Constants.JACKPOT_DRAW]: 'Draw',
        [Constants.JACKPOT_AWAY]: 'Away',
    }

    static RESULT_STATUS_OPEN = 1
    static RESULT_STATUS_LOST = 2
    static RESULT_STATUS_REFUND = 3
    static RESULT_STATUS_WIN = 4

    static RESULT_STATUS = {

        [Constants.RESULT_STATUS_OPEN]: "Open",
        [Constants.RESULT_STATUS_LOST]: "Lost",
        [Constants.RESULT_STATUS_REFUND]: "Refund",
        [Constants.RESULT_STATUS_WIN]: "Won",

    }



    static HOME_VAR = "{$competitor1}"
    static PLAYER1_VAR = "1"
    static PLAYER2_VAR = "2"
    static AWAY_VAR = "{$competitor2}"
    static DRAW_VAR = "draw"
    static NO_DRAW = "no draw"
    static HOME_OR_AWAY = "{$competitor1} or {$competitor2}"
    static DRAW_OR_HOME = "{$competitor1} or draw"
    static DRAW_OR_AWAY = "draw or {$competitor2}"

    static SALES = {
        CAN_DEPOSIT: 1,
        CAN_CANCEL_DEPOSIT: 2,
        CAN_WITHDRAW: 3,
        CAN_SELL_TICKET: 4,
        CAN_CANCEL_TICKET: 5,
        CAN_GENERATE_ADV_REP: 6,
        CAN_SELL_JACKPOT: 7,
        CAN_PAY_JACKPOT: 8,
        CAN_CANCEL_JACKPOT: 9,
        CAN_DATE_FILTER_DASHBOARD_DATE: 10
    }

    static PAYMENT = {
        MOMO: 'momo',
        BRANCH: 'branch',
        HELLOCASH: "hellocash",
        HELLOCASHWEGAGEN: "hellocashwegagen",
        AMOLE: "amole",
        DIGIMONEY: "digimoney",
        EBIRR: "ebirr",
        CBEBIRR: "cbebirr",
        TELEBIRR: "telebirr",
        DIGICARD: "digicard",
        CHAPPA: "chappa",
        MPESA: "mpesa",
        KACHA: "kacha",
        SANTIM: "santim",
        INSTACASH: "instacash",
    }

    static PAYMENT_ENABLE_OPTION = {
        DEPOSIT: 1,
        WITHDRAW: 2,
        BOTH: 3,
        NONE: 4,
    }


    static REGIONS = [
        ['hhohho', 'Hhohho'],
        ['manzini', 'Manzini'],
        ['lubombo', 'Lubombo'],
        ['shiselweni', 'Shiselweni'],

    ]

    static CONFIG = {
        WALLET_STRUCTURE: Constants.WALLET_STRUCTURE_PAYABLE,

        TEMPLATE_FILES: { 'main': 'walia.css', 'sales': 'walia-sales.css' },
        WITHHOLDTAX: 0.14,
        WIN_TAX: 0.15,
        TOT_TAX: 0.1,
        VAT_TAX: 0.15,
        TAXABLE_WIN: 1000,
        PHONE_FIELD_PLACEHOLDER: process.env.REACT_APP_PHONE_FIELD_PLACEHOLDER,
        tax_config: {
            //tax_type : Constants.TAX_TYPE_VAT,
            other_tax: process.env.REACT_APP_TAX_TYPE
        },
        BET_SLIP_BONUS: false,

        TRANSACTION_DEFAULT: "branch",


        BANK_ACCOUNT_BANNER: {
            SHOW: false,
            URL: "/lesotho-bank.jpg"
        },
        SHOW_COOKIE_CONSENT: false,

        API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
        API_BRANCH_URL: "http://localhost/branch.php",
        LOGO_H: '',
        LOGO_W: '',
        LOADING_LOGO_W: '100px',
        LOADING_BACKGROUND: process.env.REACT_APP_LOADING_BACKGROUND,
        LOADING_TYPE: process.env.REACT_APP_LOADING_TYPE || 'heart',
        SHOW_GAMEID: process.env.REACT_APP_API_SHOW_GAMEID,

        ACCOUNT_INFO_EXCL_FIELDS: [
            'kebele'
        ],
        ACCOUNT_INFO_FIELDS_LABEL: {
            'region': "Region",
            "zone": "Zone",
            "city": "City",
            "woreda": "Woreda",
            "kebele": "Kebele",
        },


        COOKIE: {
            SHOW: false,
            POLICY: "To give you the best experience possible, this website uses cookies. By viewing our content, you are accepting the use of cookies. If you want to learn more about the cookies that use,"
        },


        SCHEDULE_TIME_FORMAT: process.env.REACT_APP_SCHEDULE_TIME_FORMAT,//LT
        VERSION: 5.7,

        ENDPOINT_TYPE: Constants.DATA_TYPE_SPORTDATA,//"legacy"

        SLIP_COMP_VIEW_ORDER: [
            "total_odd",
            "placed_bet",
            "stake_tax",
            "stake",
            "winning",
            "win_tax",
            "bonus",
            "possible_win"
        ]

    }
}
